.ride-history-search {
  font-size: 14px;
  font-family: "Lato";
  input {
    border: 2px solid $resaam-gray;
    border-radius: 4px !important;
    padding: 10px 20px 10px 45px;
  }

  .search-icon {
    position: absolute;
    z-index: 999;
    top: 10px;
    left: 15px;
  }
}
