.btn-reservation {
  text-transform: uppercase;
  font-family: "Lato";
  color: white;
  background-color: $resaam-blue;
  font-size: 24px;
  font-weight: 900;

  border-radius: 100px;
  padding: 10px 0;

  width: 100%;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    background-color: darken($color: $resaam-blue, $amount: 5%);
    color: white;
  }
}

.btn-vehicletype {
  font-family: "Lato";
  color: white;
  background-color: $resaam-blue;
  font-size: 24px;
  font-weight: 900;

  border-width: 1px !important;
  border-color: #132b44 !important;
  border-radius: 100px;

  // &:hover,
  // &:focus,
  // &:active,
  // &:visited {
  //   background-color: darken($color: $resaam-blue, $amount: 5%);
  //   color: white;
  // }

  &:disabled {
    color: #999c9f;
    border-color: #999c9f !important;
  }
}
