/*     General overwrite     */

@import url("https://fonts.googleapis.com/css?family=Baloo");
@import url("https://fonts.googleapis.com/css?family=Lato");

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: "Lato";
}
body {
  background-color: $white-bg;
  color: $default-text-color;
}

.container-fluid {
  padding: $padding-default-horizontal;
}

h1 {
  color: $resaam-blue;
  font-family: "Baloo";
  font-weight: 400;
}

h5 {
  color: $resaam-blue;
  font-family: "Lato";
  font-size: 24px;
  font-weight: 900;
}

.ml-2 {
  margin-left: 2em !important;
}

.mr-2 {
  margin-right: 2em !important;
}

input {
  border: none;
  &:focus {
    outline: none;
  }
}

.row {
  margin-top: 20px;
  margin-top: 20px;
}
