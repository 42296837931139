.ongoing-ride-search {
  font-size: 14px;
  font-family: "Lato";
  input {
    border: 2px solid $resaam-gray;
    border-radius: 4px !important;
    padding: 10px 20px 10px 45px;
  }

  .search-icon {
    position: absolute;
    z-index: 999;
    top: 10px;
    left: 15px;
  }
}

.container-input-calendar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999999 !important;
}

.custom-file-upload > input[type="file"] {
  display: none;
}

// .custom-file-upload {
//   border: 1px solid #ccc;
//   display: inline-block;
//   padding: 6px 12px;
//   cursor: pointer;
// }
