.side-bar-dialog {
  .modal-dialog {
    padding-left: 15px;
    padding-right: 15px;
    img {
      height: 70px;
      width: auto;

      margin-bottom: 10px;
    }

    h2 {
      font-size: 36px !important;
    }
  }
}

.side-bar {
  background-color: white;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 10px;
  z-index: 999;

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  // position: fixed;
  // left: 0;
  // top: 0;
  // bottom: 0;
  a {
    &:hover,
    &:visited {
      color: $resaam-blue;
    }
  }

  .__hover {
    display: none;
  }
  .avatar {
    padding-top: 30px;
    h2 {
      font-family: "Baloo";
      color: $resaam-blue;
      padding-top: 5px;
    }
  }

  .__hover {
    display: none;
  }

  /* &:hover .avatar {
  
    flex-direction: row !important;
    align-items: center;
  }*/

  /*
  &:hover .__hover {
    
    display: inline-block;

    li:first-child {
      img {
        background-color: transparent;
        padding: 0px;
        margin-left: 10px;
      }

      .active img {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }

      .active {
        border-radius: 10px;
        -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      }
    }

    li:first-child a {
      background-color: $resaam-blue;
      color: white;
      font-weight: 400;
      border-radius: 10px;
      margin-left: 10px;
      margin-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  */

  &:hover .__no-hover {
    display: none;
  }

  /*&:hover .logo {
    img {
      width: 180px;
    }
  }*/

  .logo {
    img {
      width: 100px;
    }
  }

  li:first-child {
    img {
      background-color: $resaam-blue;
      padding: 13px;
      border-radius: 10px;
    }

    .active {
      border-radius: 0px;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  li {
    padding-top: 20px;

    a {
      font-family: "Lato";
      color: $resaam-blue;
      font-size: 18px;
      font-weight: 900;
    }

    .active {
      border-radius: 10px;
      -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }
  }

  li:first-child {
    padding-top: 10px;

    .active img {
      -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    }
  }

  .setings {
    .rc-menu-container {
      font-family: "Lato";
      font-size: 14px;
      ul {
        li {
          &.logout {
            color: $resaam-danger-color;
          }
          &:hover {
            background-color: $resaam-baby-blue;
          }
        }
      }
    }

    img {
      &:hover {
        cursor: pointer;
      }
    }

    p {
      font-family: "Lato";
      font-size: 14px;
      font-style: italic;
      color: #c4c4c4;
      margin-top: 4px;
    }
  }

  // .side-bar-wrapper {
  //   // padding-top: 10px;
  // }
}

@media (max-width: $screen-md) {
  .side-bar {
    position: fixed;
    z-index: 1000;
    // top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

/*.side-bar {
    border-top-right-radius: 40px;
    padding: 10px;
    z-index: 999;

    -webkit-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 4px 18px rgba(0, 0, 0, 0.25);
    box-shadow: 0 4px 18px rgba(0, 0, 0, 0.25);

    .side-bar-wrapper {
        padding-top: 10px;
        .navigation {
            .avatar {
                padding-top: 30px;
                h2 {
                    font-family: "Baloo";
                    color: $resaam-blue;
                    padding-top: 5px;
                }
            }

            .__hover {
                display: none;
            }

            &:hover .avatar {
                flex-direction: row !important;
                align-items: center;
            }

            &:hover .__hover {
                display: inline-block;

                li:first-child {
                    img {
                        background-color: transparent;
                        padding: 0px;
                        margin-left: 10px;
                    }

                    .active img {
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                    }

                    .active {
                        border-radius: 10px;
                        -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
                        -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
                    }
                }

                li:first-child a {
                    background-color: $resaam-blue;
                    color: white;
                    font-weight: 400;
                    border-radius: 10px;
                    margin-left: 10px;
                    margin-right: 10px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    display: flex;
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
            }

            &:hover .__no-hover {
                display: none;
            }

            &:hover .logo {
                img {
                    width: 180px;
                }
            }

            .logo {
                img {
                    width: 100px;
                }
            }

            li:first-child {
                img {
                    background-color: $resaam-blue;
                    padding: 13px;
                    border-radius: 10px;
                }

                .active {
                    border-radius: 0px;
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                }
            }

            li {
                padding-top: 20px;

                a {
                    font-family: "Lato";
                    color: $resaam-blue;
                    font-size: 18px;
                    font-weight: 900;
                }

                .active {
                    border-radius: 10px;
                    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                    -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                }
            }

            li:first-child {
                padding-top: 10px;

                .active img {
                    -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
                    -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
                    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
                }
            }
        }
    }

    .version {
        p {
            font-family: "Lato";
            font-size: 14px;
            font-style: italic;
            color: #c4c4c4;
            margin-top: 4px;
        }
    }
}*/
