.no-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text-no-data {
    color: #999c9f;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }
}
