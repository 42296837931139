.login-page {
  .error {
    color: red;
  }
  .btn {
    font-size: 14px;
    color: $default-text-color;

    &.btn-login {
      font-weight: bold;
      padding: 10 100px;
      height: max-content;
      width: max-content;
      border-radius: 50px;
      -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

      &:hover {
        color: $resaam-dark-blue;
        border: 2px solid $resaam-dark-blue;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
      }
    }
    &.btn-link {
      font-style: normal;
      text-decoration: none;

      &:hover {
        color: $resaam-blue;
      }
    }
  }
  img {
    &.logo {
      width: 200px;
    }
    @media screen and (max-width: 1280px) {
      &.logo {
        width: 150px;
      }
    }
  }

  .row {
    margin: 0;
    padding: 0;
    z-index: 9;
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  // media query on class .card if width < 700px p = 0

  @media screen and (max-width: 700px) {
    .card {
      padding: 0;
    }
  }
}
