$waves-bg-img: url("../../img/waves-bg.svg");
$resaam-logo: url("../../img/resaam-logo.svg");

$screen-xs: 767px;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

$white-bg: #ffffff !default;
$resaam-blue: #0096ac;
$resaam-dark-blue: #132b44;
$resaam-baby-blue: #ebf5f8;
$resaam-danger-color: #ce5347;
$resaam-gray: #999c9f;

$default-text-color: black;

$sidebar-width: calc(100% - 125px) !default;

$padding-default-vertical: 10px !default;
$padding-default-horizontal: 30px !default;
