.floating-input-field {
  font-family: "Lato";
  font-size: 16px;
  color: black;

  max-height: 40px;

  &.form-group {
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: transparent;
      opacity: 0; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: transparent;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: transparent;
    }

    input {
      //border: none !important;
      box-shadow: none;

      border: 3px solid $resaam-dark-blue;
      border-radius: 4px;

      padding-left: 20px;
      padding-right: 20px;
      //min-width: 250px;
      min-width: 100px;

      &.error {
        border-color: $resaam-danger-color;
      }
    }
    label {
      font-family: "Lato";
      font-size: 16px;
      color: black;
      //padding-bottom: 0px;
      bottom: 34px;
      left: 20px;
      position: relative;
      pointer-events: none;
    }

    &:focus-within label {
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
      transform: translate(1px, -100%);
    }

    input:not(:placeholder-shown) ~ label {
      //color: $resaam-blue;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
      transform: translate(1px, -100%);
    }

    /*input:not(:placeholder-shown).error ~ label {
            color: red;
        }*/

    .input-group {
      input {
        border-right: none;
      }

      .input-group-text {
        background-color: transparent;
        border-bottom-right-radius: 20px !important;
        border-top-right-radius: 20px !important;
        border-width: 3px;
        border-style: solid;
        border-color: $resaam-dark-blue;
        border-left: none;
      }

      label {
        position: absolute;
        top: 10px;
        z-index: 999;
        height: 25px;
        background-color: white;
      }

      &:focus-within label {
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
        transform: translate(1px, -30px);
      }

      input:not(:placeholder-shown) ~ label {
        //color: $resaam-blue;
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
        transform: translate(1px, -30px);
      }
    }
  }
}
