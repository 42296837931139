.form-check-input {
  border: 2px solid $resaam-dark-blue;
  padding: 6px;
  color: $resaam-dark-blue;
  background-color: white;

  &:checked {
    background-color: $resaam-dark-blue;
    border: 2px solid $resaam-dark-blue;
  }

  &:focus {
    border: 2px solid $resaam-dark-blue;
  }
}
