.modal {
  .modal-dialog {
    //
    //

    .modal-content {
      border-radius: 4px;
      //height: 80vh;
      .modal-header {
        justify-content: center !important;
        border: none !important;

        .modal-title {
          display: block;
          color: $resaam-blue;
          font-family: "Baloo";
          font-size: 34px;
          font-weight: 400;
        }

        button {
          position: absolute;
          right: 15px;
          top: 10px;
          width: 28px;
          height: 28px;
          background: url("../../img/close-button.svg");
          border: none;
          span {
            visibility: hidden;
          }
        }
      }

      .modal-body {
        font-family: "Lato";
        color: black;
        font-size: 14px;
        font-weight: 400;
        padding-top: 35px;
        padding-bottom: 20px;

        .row {
          .col {
            height: 60vh;
          }
        }
        h2 {
          font-size: 32px;
          color: $resaam-dark-blue;
          font-weight: 900;
        }
      }

      .modal-footer {
        border: none;

        .btn {
          border-radius: 4px;
          border: none;
          flex: 1;
          margin-left: 30px;
          margin-right: 30px;
          //padding-left: 20px;
          //padding-right: 20px;

          &.btn-primary {
            background-color: $resaam-dark-blue;
            color: white;
          }

          &.btn-secondary {
            background-color: white;
            color: $resaam-dark-blue;

            -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
