.date-time-input-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 40px;
  border-radius: 20px;
  border: 3px solid $resaam-dark-blue;
  align-items: center;

  &.disabled {
    border: 3px solid #999c9f;
  }

  span {
    display: block;

    padding-right: 20px;
    &.date {
      padding-left: 20px;
      width: 60% !important;
    }
    &.time {
      width: 40% !important;
    }
  }

  button {
    border: none;
    border-radius: 0px;
    background-color: $resaam-dark-blue;
    &:disabled {
      background-color: #999c9f;
    }
    &:hover,
    &:active,
    &:visited,
    &:focus {
      background-color: $resaam-blue;
    }
    &.date {
      margin-right: 20px;
    }

    &.time {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      margin-right: -1px;
    }
  }
}
