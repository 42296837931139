.new-ride-form {
  flex: 1;
  //overflow-y: scroll;
  overflow-y: hidden;
  overflow-x: hidden;

  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 3.8em;

  background-color: transparent;

  &.maximized {
    width: 80%;
  }

  &.minimized {
    width: 40%;
  }

  &.minimized-with-serie {
    width: 75%;
  }

  .ride-form {
    border-radius: 20px;
  }

  .row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .container-datepicker {
    border: 0;
    z-index: 0 !important;
  }
}
