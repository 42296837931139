.card {
  padding: 10px 50px;
  border-radius: 25px;
  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border: none;
}
.card-body {
  //padding-bottom: 15px;
  //padding-top: 10px;
}

.card-form {
  overflow-y: auto !important;

  /* Small Devices, Tablets */
  @media only screen and (min-height: 768px) {
    max-height: 650px;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-height: 868px) {
    max-height: 750px;
  }

  /* Medium Devices, Desktops */
  @media only screen and (min-height: 968px) {
    max-height: 850px;
  }
}

.card-title {
  &.h5 {
    font-family: "Lato";
    font-size: 24px;
    color: $resaam-blue;
    font-weight: 900;
    padding-left: 30px;
  }
}

.samu-bilan-card {
  border-radius: 8px;
  border-width: 5px;
  border-color: #0096ac;
}
.samu-bilan-card-danger {
  border-radius: 8px;
  border-width: 5px;
  border-color: #ff1400;
}
