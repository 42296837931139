.vehicle-options {
  .option {
    span {
      display: block;
      font-family: "Lato";
      font-size: 14px;
      font-style: italic;
      color: #999c9f;
    }

    &.selected {
      span {
        color: $resaam-blue;
      }

      /*.option-icon {
                filter: url("#inner-shadow");
            }*/

      .active-f {
        fill: $resaam-blue;
      }

      .active {
        stroke: $resaam-blue;
      }
    }
  }
  .option:hover {
    cursor: pointer;
    .option-icon {
      filter: url("#inner-shadow");
    }
    span {
      color: $resaam-blue;
    }

    .active-f {
      fill: #111111;
    }

    .active {
      stroke: black;
    }
  }
  .option[disabled] {
    opacity: 0.5;
    pointer-events: none;
  }
}
