.history-bilan-row {
  border-radius: 4px;

  font-family: "Lato";
  font-weight: 400;

  border: 1px solid #999c9f;
  border-radius: 4;
  display: flex;
  flex-direction: column;
  // padding: "5px 30px";

  div {
    &.medium {
      padding-left: 15px;
      padding-right: 15px;
      width: 250px;
    }
    &.small {
      padding-left: 15px;
      padding-right: 15px;
      width: 200px;
    }
    &.x-small {
      padding-left: 15px;
      padding-right: 15px;
      width: 150px;
    }
  }

  .cell {
    // flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: black;
    margin: 0;

    &.name {
      text-transform: capitalize;
    }

    &.incoming {
      color: black !important;
    }
    &.pending {
      color: #17486c;
    }

    &.onway {
      color: #035fa3;
    }
    &.waiting {
      color: #0096ac;
    }

    &.onboard {
      color: #02a76c;
    }

    &.accepted {
      color: #132b44;
    }
  }

  span {
    font-size: 14px;
    display: block;
  }

  .ssn {
    font-style: italic;
    color: #999c9f;
  }

  .datetime {
    font-size: 18px;
    font-weight: 700;
  }

  .waiting-time {
    .label {
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .patient-info {
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 5px;
  }
}
.btn-outline-primary {
  border-color: #17486c !important;
  border-radius: 4px !important;
  padding: 3px 7px !important;
  color: #17486c;
  font-size: 12px;
  font-weight: 700;

  // &:hover {
  //   background-color: $resaam-dark-blue !important;
  //   color: white;
  // }
}

.status {
  padding: 4px 18px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;

  &.incoming {
    // background-color: black !important;
    color: black;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  &.pending {
    // background-color: #17486C;
    color: #0093ff;
    background: rgba(0, 147, 255, 0.2);
  }

  &.onway {
    // background-color: #035FA3;
    color: #035fa3;
    background: rgba(3, 95, 163, 0.2);
  }
  &.waiting {
    // background-color: #0096AC;
    color: #0296ae;
    background: rgba(2, 150, 174, 0.2);
  }

  &.onboard {
    // background-color: #02A76C;
    background: rgba(2, 167, 108, 0.2);
    color: #02a76b;
  }

  &.accepted {
    // background-color: #132B44;
    color: rgba(44, 107, 51, 0.84);
    background: rgba(126, 247, 160, 0.65);
  }

  &.unavailable {
    // background-color: #D1495B;
    background: rgba(255, 23, 0, 0.2);
    color: #ff1700;
  }

  &.small {
    padding: 4px 10px;
    font-size: 13px;
  }
}

.status-point {
  border-radius: 100px;
  width: 7px;
  height: 7px;
  margin-right: 5px;

  &.small {
    width: 4px;
    height: 4px;
  }
}

.status-incoming {
  background-color: black;
}
.status-pending {
  background-color: #0093ff;
}
.status-onway {
  background-color: #035fa3;
}
.status-waiting {
  background-color: #0296ae;
}
.status-onboard {
  background-color: #02a76b;
}
.status-accepted {
  background-color: rgba(44, 107, 51, 0.84);
}
.status-unavailable {
  background-color: #ff1700;
}
