.patient-steps-btn {
  ol {
    list-style-type: none;
    padding: 0;

    margin: 0;

    li {
      min-width: 65px;
      cursor: pointer;
    }
  }

  li .step-line {
    height: 5px;
    width: 10px;
    background-color: $resaam-baby-blue;
    border-radius: 0px;
    &.active {
      background-color: $resaam-blue;
    }
  }

  li:first-child .step-line {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }

  li:last-child .step-line {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }

  svg {
    margin-left: -2px;
    margin-right: -2px;
    z-index: 999;
  }

  span {
    font-family: "Lato";
    font-style: italic;
    font-size: 12px;
    color: $resaam-baby-blue;

    &.active {
      color: $resaam-blue;
    }
  }
}

.patient-steps-btn:hover .next-step {
  stroke: $resaam-blue;

  color: $resaam-blue;
}
