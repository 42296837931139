.test-me {
  .address-autocomplete-container {
    border: 3px solid $resaam-dark-blue;
    border-radius: 20px;
    padding: 0px;
    padding-left: 6px;
    padding-right: 6px;
    min-width: 250px;

    font-family: "Lato";
    font-size: 16px;
    color: black;

    height: 40px;

    .address-autocomplete__control {
      background-color: transparent;
      height: 36px;
      min-height: 36px;
    }

    input {
      opacity: 1 !important;
    }

    /*div {
            overflow: visible !important;
        }*/

    .address-autocomplete__menu {
      background-color: $resaam-dark-blue;
      color: white;
      margin-top: 0;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;

      .address-autocomplete__option--is-focused {
        background-color: inherit;
        color: $resaam-blue;
      }

      .address-autocomplete__option--is-selected {
        background-color: $resaam-blue;
        color: inherit;
      }

      .address-autocomplete__option--is-selected:last-child {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }

  .address-autocomplete-floating.form-group {
    .address-autocomplete__control {
      border: none !important;
      box-shadow: none;
    }
    label {
      font-family: "Lato";
      font-size: 16px;
      color: black;

      bottom: 34px;
      left: 20px;
      position: relative;
      pointer-events: none;
    }

    &:focus-within label {
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
      transform: translate(1px, -100%);
    }

    &.invalid {
      .address-autocomplete-container {
        border-color: $resaam-dark-blue;
      }
      label {
        color: black;
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
        transform: translate(1px, -100%);
      }
    }

    &.valid {
      .address-autocomplete-container {
        border-color: $resaam-blue;
      }
      label {
        color: $resaam-blue;
        background-color: white;
        padding-left: 5px;
        padding-right: 5px;
        transform: translate(1px, -100%);
      }
    }

    &.error {
      .address-autocomplete-container {
        border-color: red;
      }
    }
  }
}
