.wrapper {
  min-height: 100%;
}

.text-divider {
  margin: 2em 0;
  line-height: 0;
  text-align: center;
}
.text-divider span {
  background-color: white;
  padding: 1em;
}
.text-divider:before {
  content: " ";
  display: block;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #f7f7f7;
}
.psc-image {
  width: 80%;
  cursor: pointer;
}

.psc-image-setting-disabled {
  filter: grayscale(100%);
}
.psc-image-setting {
  width: 200px;
  cursor: pointer;
}

.menu-deroulant {
  cursor: pointer;
}

.profile-private {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 999;
  padding: 5px 20px;
  // border-radius: 0 0 4px 4px;
  border-radius: 0 0 0 12px;

  display: flex;
  align-items: center;
  gap: 20px;

  color: black;
  background-color: #e8e8e8;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.settings-mobile {
  display: none;
  position: fixed;
  right: 40px;
  bottom: 20px;
  z-index: 1000;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 100px;
  padding: 5px;
}

// histe .profile private when size < 992px
@media (max-width: 991px) {
  .profile-private {
    display: none;
    position: relative;
  }

  .settings-mobile {
    display: block;
  }
}
