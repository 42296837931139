.floating-select-label {
  position: relative;

  border: 3px solid $resaam-dark-blue;
  border-radius: 20px;
  padding: 8px 10px;

  font-weight: 400;

  max-height: 40px;

  &.error {
    border-color: $resaam-danger-color;
  }

  select {
    border: none;
    border-radius: 0;
    width: 100%;
    display: block;
    padding-left: 4px;
    padding-top: 0px;
    background-color: transparent;
    //appearance: none;

    &:focus {
      outline: none;
    }

    &:focus ~ label {
      -webkit-animation: inputHighlighter 0.3s ease;
      -moz-animation: inputHighlighter 0.3s ease;
      animation: inputHighlighter 0.3s ease;
    }

    &:focus ~ label,
    &.checked ~ label {
      top: -20px;
      left: 20px;
      color: black;
      font-size: 14px;
      background-color: white;
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  label {
    color: black;
    font-size: 14px;
    font-family: "Lato";
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 6px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /*.floating-select {
        font-size: 14px;
        padding: 4px 4px;
        display: block;
        width: 100%;
        height: 30px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #757575;
    }

    .floating-select:focus {
        outline: none;
        border-bottom: 2px solid #5264ae;
    }

    

    .floating-select:focus ~ label,
    .floating-select:not([value=""]):valid ~ label {
        top: -18px;
        font-size: 14px;
        color: #5264ae;
    }

    /* active state *
    .floating-select:focus ~ .floating-select:focus ~ {
        width: 50%;
    }

    *,
    *:before,
    *:after {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    /* active state *
    .floating-select:focus ~ {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
    }*/
}
