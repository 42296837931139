.image-button {
  background-color: $resaam-blue;
  border: none;
  border-radius: 25px;
  height: 40px;
  padding: 0 20px;
  img {
    margin-right: 10px;
  }

  &:hover,
  &:focus,
  &:active,
  &:visited {
    background-color: $resaam-dark-blue !important;
  }

  &.round {
    padding: 0px;
    width: 50px;
    //background-color: $resaam-dark-blue !important;

    img {
      margin: 0px;
      padding: 0px;
    }

    /*&:hover {
            background-color: $resaam-blue !important;
        }*/
  }

  &.btn-sm {
    width: 34px;
    height: 34px;
  }
}
