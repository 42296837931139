.ongoing-serie-row {
  // margin: 15px 10px;
  padding: 6px 30px;
  border-radius: 4px;
  border: 1px solid #999c9f !important;
  height: 120px;
  width: 986px;

  font-family: "Lato";
  font-weight: 400;
  position: relative;

  div {
    &.medium {
      padding-left: 15px;
      padding-right: 15px;
      width: 250px;
    }
    &.small {
      padding-left: 15px;
      padding-right: 15px;
      width: 200px;
    }
    &.x-small {
      padding-left: 15px;
      padding-right: 15px;
      width: 150px;
    }
  }

  .badge-serie {
    background-color: #132b44;
    border-radius: 25px;
    padding: 3px 15px;

    color: #fff;
    font-size: 12px;
  }
  .cell {
    // flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: black;
    margin: 0;

    &.name {
      text-transform: capitalize;
    }

    &.incoming {
      color: black !important;
    }
    &.pending {
      color: #17486c;
    }

    &.onway {
      color: #035fa2;
    }
    &.waiting {
      color: #0067b2;
    }

    &.onboard {
      color: #02a76b;
    }

    &.arrived {
      color: #132b44;
    }

    &.accepted {
      color: #132b44;
    }
  }

  span {
    font-size: 14px;
    display: block;
  }

  .ssn {
    font-style: italic;
    color: #999c9f;
  }

  .datetime {
    font-size: 18px;
    font-weight: 700;
  }

  .patient-info {
    font-size: 14px;
    text-decoration: underline;
    margin-bottom: 5px;
  }

  .status {
    padding: 4px 20px;
    border-radius: 100px;
    color: white;

    &.completed,
    &.incoming {
      background-color: red;
    }

    &.onway {
      background-color: #035fa3;
    }

    &.canceled {
      background-color: $resaam-baby-blue;
      color: black;
    }

    &.accepted {
      background-color: #02a76b;
    }

    &.unavailable {
      background-color: #d1495b;
    }
  }
}
.btn-outline-primary {
  border-color: #17486c !important;
  border-radius: 4px !important;
  padding: 3px 7px !important;
  color: #17486c;
  font-size: 12px;
  font-weight: 700;

  &:hover {
    background-color: #efefef !important;
    color: #17486c;
  }

  &:disabled {
    color: #999c9f;
    border-color: #999c9f !important;
  }
}

.text-truncate-2 {
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
}

.status-serie {
  padding: 4px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &.canceled {
    background-color: $resaam-baby-blue;
    color: black;
  }

  &.incoming {
    // background-color: black !important;
    color: black;
    background-color: rgba(0, 0, 0, 0.2) !important;
  }
  &.pending {
    // background-color: #17486C;
    color: #0093ff;
    background: rgba(0, 147, 255, 0.2);
  }

  &.onway {
    // background-color: #035FA3;
    color: #035fa3;
    background: rgba(3, 95, 163, 0.2);
  }

  &.arrived {
    // background-color: #132B44;
    color: rgba(44, 107, 51, 0.84);
    background: rgba(126, 247, 160, 0.65);
  }

  &.accepted {
    // background-color: #132B44;
    color: rgba(44, 107, 51, 0.84);
    background: rgba(126, 247, 160, 0.65);
  }

  &.unavailable {
    // background-color: #D1495B;
    background: rgba(255, 23, 0, 0.2);
    color: #ff1700;
  }

  &.small {
    padding: 4px 10px;
    font-size: 13px;
  }
}
