.ride-history-row {
  margin: 15px 10px;
  border-radius: 4px;

  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

  font-family: "Lato";
  font-weight: 400;

  div {
    &.small {
      padding-left: 15px;
      padding-right: 15px;
      width: 200px;
    }
    &.x-small {
      padding-left: 15px;
      padding-right: 15px;
      width: 150px;
    }
  }

  .cell {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding-left: 10px;
    padding-right: 10px;
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    color: black;

    &.name {
      text-transform: capitalize;
    }
  }

  span {
    font-size: 14px;
    display: block;
  }

  .ssn {
    font-style: italic;
    color: #999c9f;
  }

  .datetime {
    font-size: 18px;
    font-weight: 700;
  }
}

.status-history {
  padding: 4px 20px;
  border-radius: 4px;
  display: flex;
  align-items: center;

  &.completed {
    background: rgba(26, 5, 29, 0.2);
    color: #1a051d;
  }

  &.canceled {
    // background-color: #999C9F;
    background: rgba(255, 23, 0, 0.2);
    color: #ff1700;
  }

  &.small {
    padding: 4px 10px;
    font-size: 13px;
  }
}

.status-point {
  border-radius: 100px;
  width: 7px;
  height: 7px;
  margin-right: 5px;

  &.small {
    width: 4px;
    height: 4px;
  }
}

.status-completed {
  background-color: #1a051d;
}
.status-canceled {
  background-color: #ff1700;
}
