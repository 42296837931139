.patient-list-row {
  margin: 15px 10px;
  border-radius: 4px;

  -webkit-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);

  font-family: "Lato";

  h2 {
    font-size: 24px;
    color: $resaam-blue !important;
    margin: 0;
  }

  span {
    font-size: 14px;
  }
}
